
 .incident-template-builder {
	.fluid-form>div.box-round {
		display: block;
	}
	.fluid-form>div:not(.button-row)>div>input[type=text] {
        width: auto;
        width: ~"calc(100% - 20px)";
    }

	.fluid-form>div:not(.button-row)>div>textarea {
		width: auto;
		width: ~"calc(100% - 18px)";
	}
	.fluid-form>div:not(.button-row)>div>select {
		width: auto;
		width: ~"calc(100% - 10px)";
	}
	.fluid-form {
		h2,
		h3,
		h4 {
			margin-left: 10px;
			margin-right: 10px;
		}
		div.fluid-one-line {
			//used for radios and checkboxes
			display: flex;
			width: 95%;
			flex: 1 1 auto;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;
			>div:not(.fluid-one-line) {
			    width:100%;
			    flex-wrap: wrap;
				display: flex;
				flex: 1 1 auto;
				flex-direction: row;
				align-items: flex-start;
				margin-top: 5px;
				input[type="radio"],
				input[type="checkbox"] {
					margin: .3em 5px 0 0;
				}
				textarea {
				width: 100%;
				}
				 label {    padding: 0 0 0 5px; }
			}
		}
		&>div {
			//fluid rows
			display: flex;
			align-items: flex-start;

			&>div {
				//fluid row blocks
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				flex-basis: 25%;
				position: relative;
				label {}

				em:nth-child(3) {
				    position: absolute;
					top: 16px;
					right: -2px;
                    a {font-size: medium;
                       margin-right: -5px;}
                    font-style: normal;
				}
				em:nth-child(2) {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    a {font-size: medium;
                       margin-right: -5px;}
                    font-style: normal;
                }
                b + em:nth-child(2) {
                                    position: relative;
                                    top: -3px;
                                    right: 3px;
                                    a {font-size: medium;
                                       margin-right: -5px;}
                                    font-style: normal;
                                }
                b + em:nth-child(3) {
                                    position: relative;
                                    top: -3px;
                                    right: 3px;
                                    a {font-size: medium;
                                       margin-right: -5px;}
                                    font-style: normal;
                                }

				&>div {
					em {
						position: relative;
						top: -3px;
						right: 3px;
						a {font-size: medium;
                           margin-right: -5px;}
						font-style: normal;
					}
				}
			}
			&>div:not(.fluid-one-line):nth-child(2) {
				select+em {
					font-style: normal;
				}
			}
		}
		input {
			line-height: 1.5em;
		}
		input+em,
		select+em,
		textarea+em,
		.ui-datepicker-append+em,
		.ui-datepicker-trigger+em {
			font-size: 150%;
			margin-left: 5px;
			vertical-align: top;
		}
		@media screen and (-webkit-min-device-pixel-ratio:0) {
			/*safari and chrome*/
			select {
				height: 32px;
				line-height: 32px;
			}
		}
		.error-message {
			margin: 0;
		}
	}
	.fluid-form>div:not(.button-row):nth-of-type(odd):not(.button-row):not(.no-style) {
		background-color: #fff;
	}
	.step-content {
		padding: 0 ~"calc(50% - 360px)";
		max-height: calc(~"97vh - 100px");
	}

	.incident-template-content-hide {
        visibility: hidden;
    }
}

.dialog-drawer-outer-h4 {
     margin-left: 10px;
     margin-right: 10px;
    }
.dialog-drawer-inner-h4 {
     margin-left: 10px;
	 margin-right: 10px;
	 margin-top: -10px;
	}
.table-label{
             tr td:nth-child(1) {
             label{
             font-weight: normal;
             }
              width:auto;
             }
             tr td:nth-child(2) {
              width:auto;
              label{
                 font-weight: normal;
                 }
             }
             tr td:nth-child(3) {
               width:70px;
               font-weight: bold;
               input[type="checkbox"]
                {vertical-align: top;
             }
            }
            tr td:last-child {
              width:70px;
              font-weight: bold;
              input[type="checkbox"] {
                    vertical-align: top;
               }
            }

         }

.incident-template-grid-table {
     tr th:first-child {
         width:1%;
     }
     tr th:nth-child(2) {
             width:auto;
         }
     tr th:nth-child(3) {
                  width:auto;
              }
     tr th:last-child {
             width:1%;
         }

 }
.incident-template-grid-table-order {
     tr th:first-child {
         width: 6em;
         min-width: 6em;
         max-width: 6em;
     }
     tr th:nth-child(2) {
         width:auto;
     }
     tr th:nth-child(3) {
             width:auto;
         }
     tr th:last-child {
             width:1%;
         }
     tr td:first-child {
         white-space: nowrap;
     }
     input[type="text"] {
         width: 3em;
      }

 }
.incident-template-grid-table-edit {
     tr th:first-child {
         width:1%;
     }
     tr th:nth-child(2) {
         width:1%;
     }
     tr th:nth-child(3) {
              width:auto;
          }
     tr th:nth-child(4) {
             width:auto;
         }
     tr th:last-child {
             width:1%;
         }

 }